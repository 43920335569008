import Image from 'next/image'
import { useSelector } from 'react-redux'
import { SimplyLogo } from '~/components/Logo/SimplyLogo'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import HelloAgain from '../../../public/login-image/helloAgain.svg'
import Simpl from '../../../public/login-image/login-worker-img.svg'
import Signin from '../../../public/login-image/signIn.svg'

interface AuthLayoutBoxProps {
    /**
     * Title
     */
    title: string
    /**
     * Extend element
     */
    children?: JSX.Element | JSX.Element[]
}

const AuthLayoutBox = ({ children }: AuthLayoutBoxProps) => {
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any

    return (
        <div className="flex flex-col justify-center items-center max-w-full h-screen bg-[linear-gradient(to_bottom,rgba(255,216,198,1),rgba(255,241,234,1))]">
            <div className="flex flex-col items-start bg-image-inlogin py-12">
                <div className="mb-6 flex justify-center w-full">
                    <SimplyLogo></SimplyLogo>
                </div>
                <div className="mb-3 flex justify-center w-full">
                    <Image src={HelloAgain} />
                </div>
                <div className="mb-6 flex justify-center w-full">
                    <Image src={Signin} />
                </div>
                <div className="mb-6 flex justify-center w-full">
                    <Image src={Simpl} />
                </div>
                <div className="rounded-2xl px-4 flex flex-col items-center w-full">
                    <div className="w-72 max-w-full mx-auto">{children}</div>
                </div>
                {/* <a
                    href="/privacy"
                    target="_blank"
                    className="text-[#111111] text-opacity-[80%]  text-[14px]  text-center  w-full mt-5 cursor-pointer">
                    By signing in, you agree to Simply's <span className="text-[#FF5200]">Privacy Policy</span>
                </a> */}
                <a
                    href="/privacy"
                    target="_blank"
                    className="text-[#111111] text-opacity-[80%]  text-[14px]  text-center  w-full mt-5 cursor-pointer">
                    {localeText?.common?.privacyPolicyOne}
                    <span className="text-[#FF5200]"> {localeText?.common?.privacyPolicyTwo} </span>
                    {`${localeText?.common?.privacyPolicyThree ? localeText?.common?.privacyPolicyThree : ''}`}
                </a>
            </div>
        </div>
    )
}

export default AuthLayoutBox
