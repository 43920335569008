import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'
import { useSelector } from 'react-redux'

// import { Meta } from '~/components'
import { useAuth } from '~/components/Auth/AuthProvider'
import { Button } from '~/components/Common/Button'
import { Tooltip } from '~/components/Common/Tooltip'
import Icon from '~/components/Icon'
import { GoogleIcon } from '~/components/Icon/GoogleIcon'
import AuthLayoutBox from '~/components/Layout/AuthLayout/AuthLayoutBox'
import locale from '~/locale'
import { RootState } from '~/redux/store'

dayjs.extend(utc)

export const LoginPage = () => {
    const { googleSignIn, appleSignIn } = useAuth()
    const [loading, setLoading] = useState(false) // Step 1: Create loading state
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any

    const handleGoogleSignIn = async () => {
        setLoading(true)
        await googleSignIn('login', setLoading)
    }
    return (
        <>
            <AuthLayoutBox title={'Login'}>
                <div id="login" className="w-full relative bg-transparent">
                    <div className="grid grid-cols-1 gap-5">
                        {/* <Button
                            type="outlined"
                            text={locale.id.common.enter}
                            fullWidth
                            onClick={showOtpLessWidget}></Button> */}
                        <Button
                            type="outlined"
                            text={`${localeText?.common?.login?.google}`}
                            loading={loading}
                            icon={
                                <>
                                    <GoogleIcon></GoogleIcon>
                                    <div className="w-2"></div>
                                </>
                            }
                            fullWidth
                            onClick={handleGoogleSignIn}></Button>
                        {/* <Button
                            type="outlined"
                            text="Masuk dengan Apple"
                            backgroundColor="#000"
                            color="#fff"
                            icon={
                                <>
                                    <AppleIcon></AppleIcon>
                                    <div className="w-2"></div>
                                </>
                            }
                            fullWidth
                            onClick={appleSignIn}></Button> */}
                        {/* <p className="text-center text-sm pt-3 border-t">Don't have an account?</p> */}
                        {/* <Button
                            icon={
                                <>
                                    <GoogleIcon></GoogleIcon>
                                    <div className="w-2"></div>
                                </>
                            }
                            text="Sign up with Google"
                            fullWidth
                            type="outlined"
                            onClick={() => {
                                googleSignIn('register')
                            }}></Button> */}
                        {/* <Button
                            icon={
                                <>
                                    <AppleIcon></AppleIcon>
                                </>
                            }
                            text="Daftar dengan Apple"
                            fullWidth
                            backgroundColor="#000"
                            color="#fff"
                            type="outlined"
                            onClick={() => {
                                appleSignIn('register')
                            }}></Button> */}
                    </div>
                    {/* <div className="text-center text-sm font-normal mt-5">
                        <p className="text-neutrals-900">{locale.id.common.terms.text}</p>
                        <a
                            className="text-primary-600 cursor-pointer no-underline"
                            href="https://kelola.co/privacy"
                            target="_blank"
                            rel="noreferrer">
                            {locale.id.common.terms.link}
                        </a>
                    </div> */}
                </div>
            </AuthLayoutBox>
            <a href="https://wa.me/+919929970571" target="_blank" rel="noreferrer">
                <div
                    className="fixed right-6 bottom-6 rounded-full bg-green-400 hover:bg-green-500 transition-colors text-neutrals-light flex items-center justify-center p-3"
                    role="button">
                    <Tooltip content={'Chat on WhatsApp'} variant="information">
                        <div className="flex items-center justify-center">
                            <Icon width={32} name="chat-outline"></Icon>
                        </div>
                    </Tooltip>
                </div>
            </a>
        </>
    )
}

export default LoginPage
